<template>
  <div class="vx-row">
    <div class="vx-col md:w-3/4">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="4000"
        :filename="`${CodeCmde}`"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"

        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
      >
        <section slot="pdf-content">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-1/2 mt-base">
              <img :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
            </div>
            <div class="vx-col w-1/2 text-right">
              <h3>Bon de réception</h3>
              <div class="invoice__invoice-detail mt-6">
                <h6>BR N°.</h6>
                <p>{{ CodeCmde }}</p>

                <h6 class="mt-4">DATE</h6>
                <p>{{ DateComdF | moment("calendar", "July 10 2011") }}</p>
              </div>
            </div>
            <div class="vx-col w-1/2 mt-12">
              <h5>Officine:</h5>
              <div class="invoice__recipient-info my-4">
                <p>{{ officineName }}</p>
              </div>
            </div>
            <div class="vx-col w-1/2 text-right mt-12">
              <h5>Fournisseur:</h5>
              <div class="invoice__company-info my-4">
                <p>{{ fournisseurName }}</p>
              </div>
            </div>
          </div>

          <!-- INVOICE CONTENT -->
          <div v-if="CodeCmde != ''" class="p-base">
            <table class="w-full tableCustom" cellpadding="0" cellspacing="0">
              <thead>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;">Etiquette</th>
                <th colspan="2" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Marque</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Modèle</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Couleur</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Taille</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Type</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">Qte</th>
                <th colspan="1" class="thCustom" style="border-right-width: 0px;border-left-width: 0px;">PR</th>
                <th colspan="1" class="thCustom" style="border-left-width: 0px;">PVTTC</th>
              </thead>
              <tbody>
                <tr class="trCustom" style="border-width: 0px;" v-for="(item, index) in details" :key="index" :class="[(nbreDeLignePdf(index)) ? 'html2pdf__page-break' : '' ,(nbreDeLignePdf(index)) ? 'mt-30' : '' ]">
                  <td colspan="1" class="thCustom" style="border-width: 0px;borderborder-bottom-width: 0px;border-top-width: 0px;">
                    {{item.Etiquette}}
                  </td>
                  <td colspan="2" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{getMarqueName(item.IdMarque)}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{item.Modele}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{item.Couleur}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{item.Taille[0].Longeur}}/{{item.Taille[0].Nez}}/{{item.Taille[0].Hauteur}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{ getTypeName(item.IdTypeArt)}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{item.Qte}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{moneyFormatter(item.PrixAchatBrut)}}
                  </td>
                  <td colspan="1" class="thCustom" style="border-width: 0px;border-bottom-width: 0px;border-top-width: 0px;">
                    {{moneyFormatter(item.PrixVenteTTC)}}
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="w-1/2 ml-auto mt-4" cellpadding="0" cellspacing="0">
              <tr>
                <th style="border-right-width: 0px;border-bottom-width: 0px;" class="thCustom">QTITE TOTAL:</th>
                <td style="border-left-width: 0px;border-bottom-width: 0px;" class="thCustom"> {{QteTotal}} </td>
              </tr>
              <tr>
                <th style="border-right-width: 0px;border-bottom-width: 0px;" class="thCustom">TOTAL PR:</th>
                <td style="border-left-width: 0px;border-bottom-width: 0px;" class="thCustom"> {{TotalPa}} FCFA</td>
              </tr>
              <tr>
                <th style="border-right-width: 0px;" class="thCustom">TOTAL PV TTC:</th>
                <td style="border-left-width: 0px;" class="thCustom"> {{TotalPv}} FCFA</td>
              </tr>
            </table>

            <div class="mt-5">
              Arrêté la présente facture à la somme de: {{NumberToLetter(totalpv)}}
            </div>

          </div>
        </section>
      </vue-html2pdf>
      <vx-card>
        <!-- INVOICE METADATA -->
        <div class="vx-row leading-loose p-base">
          <div class="vx-col w-1/2 mt-base">
            <img :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
          </div>
          <div class="vx-col w-1/2 text-right">
            <h3>Bon de réception</h3>
            <div class="invoice__invoice-detail mt-6">
              <h6>BR N°.</h6>
              <p>{{ CodeCmde }}</p>

              <h6 class="mt-4">DATE</h6>
              <p>{{ DateComdF | moment("calendar", "July 10 2011") }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-12">
            <h5>Officine:</h5>
            <div class="invoice__recipient-info my-4">
              <p>{{ officineName }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 text-right mt-12">
            <h5>Fournisseur:</h5>
            <div class="invoice__company-info my-4">
              <p>{{ fournisseurName }}</p>
            </div>
          </div>
        </div>

        <!-- INVOICE CONTENT -->
        <div v-if="CodeCmde != ''" class="">
          <vs-table hoverFlat :data="details">
            <!-- HEADER -->
            <template slot="thead">
              <vs-th class="pointer-events-none">Etiquette</vs-th>
              <vs-th class="pointer-events-none">Marque</vs-th>
              <vs-th class="pointer-events-none">Modèle</vs-th>
              <vs-th class="pointer-events-none">Couleur</vs-th>
              <vs-th class="pointer-events-none">Taille</vs-th>
              <vs-th class="pointer-events-none">Type</vs-th>
              <vs-th class="pointer-events-none">Qte</vs-th>
              <vs-th class="pointer-events-none">PR</vs-th>
              <vs-th class="pointer-events-none">PVTTC</vs-th>
            </template>
            <!-- DATA -->
            <template  class="mb-5">
              <vs-tr v-for="(item, index) in details" :key="index">
                <vs-td data-label="Etiquette">
                  {{item.Etiquette}}
                </vs-td>
                <vs-td data-label="Marque">
                  {{getMarqueName(item.IdMarque)}}
                </vs-td>
                <vs-td data-label="Modele">
                  {{item.Modele}}
                </vs-td>
                <vs-td data-label="Couleur">
                  {{item.Couleur}}
                </vs-td>
                <vs-td data-label="Taille">
                  {{item.Taille[0].Longeur}}/{{item.Taille[0].Nez}}/{{item.Taille[0].Hauteur}}
                </vs-td>
                <vs-td data-label="Type">
                  {{ getTypeName(item.IdTypeArt)}}
                </vs-td>
                <vs-td data-label="QteAchat">
                  {{item.Qte}}
                </vs-td>
                <vs-td data-label="PrixAchatBrut">
                  {{moneyFormatter(item.PrixAchatBrut)}}
                </vs-td>
                <vs-td data-label="PrixAchatBrut">
                  {{moneyFormatter(item.PrixVenteTTC)}}
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
          <vs-table hoverFlat class="w-1/2 ml-auto mt-4">
            <vs-tr>
              <vs-th class="pointer-events-none">QTITE TOTAL</vs-th>
              <vs-td> {{QteTotal}} </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none">TOTAL PR</vs-th>
              <vs-td> {{TotalPa}} FCFA</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none">TOTAL PV TTC</vs-th>
              <vs-td> {{TotalPv}} FCFA</vs-td>
            </vs-tr>
          </vs-table>
          <div class="mt-5">
            Arrêté la présente facture à la somme de: {{NumberToLetter(totalpv)}}
          </div>
        <vs-divider/>
        <!-- INVOICE FOOTER -->
        <div class="invoice__footer text-center p-1">
          <!-- <p class="mb-4">
            FOVEA Group SAS au capital de 1 000 000 FCFA-RC: CI-ABJ-2015-B-20954 -CC: 15 42870 E-Régime
              d'imposition: Réel Simplifié -
            </p>
          <p>
            <span>DGI: Marcory 1- 21 BP 5379 Abidjan 21 - Tel: 22 26 20 93 - 58 55 55 19</span><br>
            <span><span class="font-semibold"> www.anoptic.com</span></span>
          </p> -->
        </div>
        </div>

      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <div class="mt-4"><vs-button class="w-full" color="primary" type="filled" @click="PrintCommandeFourn">IMPRIMER</vs-button></div>
      <div class="mt-4" v-if="activeUserInfos.IdOfficine === IdOfficine && status === 'En attente de validation'"><vs-button class="w-full" color="primary" type="filled" @click="confirmValidationBR()">VALIDER TRANSFERT</vs-button></div>
      <div v-if="activeUserInfos.IdOfficine === IdOfficine && status === 'En attente de validation'" class="mt-4"><vs-button class="w-full" color="danger" type="filled" @click="confirmAnnulerBR()">ANNULER TRANSFERT</vs-button></div>
    </div>
  </div>
</template>

<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  name: 'Add',
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')),
      montantEnLettre: '',
      IdOfficine: '',
      officineName: '',
      CodeCmde: '',
      IdFour: '',
      fournisseurName: '',
      IdUser: '',
      RemiseCmd: 0,
      DateComdF: null,
      refArcticle: '',
      IdArticleCatalogue: '',
      IdCateg: '',
      IdStyle: '',
      IdMarque: '',
      IdTypeArt: '',
      Etiquette: '',
      Modele: '',
      Longeur: '',
      Hauteur: '',
      Nez: '',
      Couleur: '',
      Designation: '',
      UniteMesure: '',
      PrixPlanche: 0,
      PrixAchatBrut: 0,
      Remise: 0,
      PrixNet: 0,
      PrixVenteHT: 0,
      TauxTVA: 0,
      PrixVenteTTC: 0,
      CodeBarre: 0,
      stockMinimun: 0,
      QteAchat: 0,
      StockTransfere: false,
      entete: ['Etiquette', 'Marque', 'Modèle', 'Couleur', 'Taille', 'Type', 'Qte', 'PA', 'Action'],
      details: [],
      popupActive: false,
      popupUpdateActive: false,
      curentIndex: null,
      griseInputCommandeNumber: false,
      transfert_total_in_stock: false,
      status: '',
      commandeFourId: null,

      totalpv: 0,
      lastIndex: 0
    }
  },
  computed: {

    QteTotal () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.Qte * 1)
      }, 0)
      return total
    },
    TotalPa () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.PrixAchatBrut *  article.Qte)
      }, 0)
      /* Formatage du calcul */
      /* const val = (total / 1) */

      return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    TotalPv () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.PrixVenteTTC * article.Qte)
      }, 0)

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalpv = total
      /* Formatage du calcul */
      /* const val = (total / 1).replace('.', ',') */

      return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getModeleEntetePiedPageByOfficine () {
      return this.$store.getters['entete_piedpage/getModeleEntetePiedPageByOfficine'](this.IdOfficine)
    }

  },
  methods: {
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    confirmValidationBR () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Validation BR',
        text: `Vous allez valider le BR ${this.CodeCmde} vers stock, Attention cette action est irréversible!`,
        acceptText: 'VALIDER BR',
        accept: this.validationBR
      })
    },
    validationBR () {
      const payload = {
        id: this.$route.params.commandeFourId,
        IdUser: this.activeUserInfos._id
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/validationTransfertCommandeFournisseurInStockAgence', payload)
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'BR vers Stock',
            text: 'Le BR   a bien été ajouté au stock stock'
          })
          this.$router.push('/apps/stock').catch(() => {})
          this.$vs.loading.close()

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    confirmAnnulerBR () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Annulation BR',
        text: `Vous allez annuler le BR ${this.CodeCmde}!`,
        acceptText: 'ANNULER BR',
        accept: this.AnnulationBR
      })
    },
    AnnulationBR () {
      const payload = {
        id: this.$route.params.commandeFourId,
        IdUser: this.activeUserInfos._id
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/AnnulationBr', payload)
        .then(() => {
          this.$router.push('/apps/commandefourn/commandefourn-transferer').catch(() => {})

          this.$vs.notify({
            color: 'danger',
            title: 'Annulation',
            text: 'Le BR  a été annulé'
          })
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getTypeName (id) {
      const singleType = this.$store.getters['type/getTypeById'](id)
      return singleType ? singleType.Type_Abreg : ''
    },
    designationFormatter () {
      return `${this.getMarqueName()} ${this.Modele}-${this.Couleur}`
    },

    nbreDeLignePdf (index) {
      if (((index + 1) === 25)) {
        this.lastIndex = 25
        return true
      }  else if (((index + 1) === (this.lastIndex))) {
        this.lastIndex = index + 1
        return true
      } else {
        return false
      }

    },
    nbreDeLigneStyle (index) {
      if (((index + 1) % 35) === 0) {
        return true
      } else {
        return false
      }
    },
    OnSelectFournisseur (id) {
      const fournisseur = this.$store.state.fournisseur.fournisseurs.find((f) => f._id === id)
      this.fournisseurName = fournisseur.Nom
    },
    async onProgress ({html2pdf, options, pdfContent}) {
      console.log('options:', options)
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        console.log('pdf:', pdf)
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(`Page ${  i  } / ${  totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    async beforeDownload ({html2pdf, options, pdfContent}) {
      console.log('options:', options)
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        console.log('pdf:', pdf)
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(`Page ${  i  } / ${  totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    PrintCommandeFourn () {
      this.$refs.html2Pdf.generatePdf()
    },

    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getCategorieArticle () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getStyles () {
      this.$store.dispatch('style/getStyles')
        .then(() => { })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getTypes () {
      this.$store.dispatch('type/getTypes')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getCommandeFournisseurById () {

      const commandeFourn = this.$store.getters['commande_fourn/getCommandeFournisseurById'](this.$route.params.commandeFourId)

      this.onSelectOfficine(commandeFourn.IdOfficine)

      this.OnSelectFournisseur(commandeFourn.IdFour)

      this.IdOfficine = commandeFourn.IdOfficine
      this.CodeCmde = commandeFourn.CodeCmde
      this.IdFour = commandeFourn.IdFour
      this.IdUser = commandeFourn.IdUser
      this.RemiseCmd = commandeFourn.RemiseCmd
      this.DateComdF = commandeFourn.DateComdF
      this.details = commandeFourn.details
      this.etatReglement = commandeFourn.etatReglement
      this.transfert_total_in_stock = commandeFourn.transfert_total_in_stock
      this.status = commandeFourn.status

      if (commandeFourn.details.length >= 1) {
        this.IdCateg = commandeFourn.details[0].IdCateg
        const articleTransferer = commandeFourn.details.find((a) => a.StockTransfere === true)

        this.griseInputCommandeNumber = !!articleTransferer.length
      }
    },
    activeUserInfo () {
      this.IdOfficine = this.activeUserInfos.IdOfficine
    },
    getAllModelEntetePiedPage () {
      this.$store.dispatch('entete_piedpage/getAllModelEntetePiedPage')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getTransfererBonReceptionByOfficine (id) {
      const payload = {
        officineId: id
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/getTransfererBonReceptionByOfficine', payload)
        .then((resp) => {
          console.log('brs', resp.data)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.log(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getAllModelEntetePiedPage()
    if (!(this.$store.state.commande_fourn.commande_fourns_transferer.length > 0)) this.getTransfererBonReceptionByOfficine(this.activeUserInfos.IdOfficine)
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.fournisseur.fournisseurs.length > 0)) this.getFournisseurs()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticle()
    if (!(this.$store.state.style.styles.length > 0)) this.getStyles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.type.types.length > 0)) this.getTypes()
    this.getCommandeFournisseurById()
  }
}
</script>

<style lang="scss">
.not-data-table{
  display: none;
}

.trCustom, .thCustom {
  border: 1px solid black;
  padding: 5px;
  font-size: 11px;
}
</style>
